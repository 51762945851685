import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  ArrowIcon,
  Button,
  Col,
  Csku,
  Row,
  useWindowSize,
} from "@commonsku/styles";
import DashboardPageTitle from "../../dashboard/DashboardPageTitle";
import ActionMenu from "../action-menu/ActionMenu";
import ErrorBoundary from "../../ErrorBoundary";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useMatch,
  useParams,
} from "react-router-dom";
import NoMatch from "../../NoMatch";
import { ReportProvider } from "../report_context";
import { getReportConfig, ReportConfig } from "../config";
import ReportFiltersContainer from "../filters/ReportFiltersContainer";
import ReportSummaryContainer from "../summary/ReportSummaryContainer";
import ReportTable from "../table/ReportTable";
import Pagination from "../table/Pagination";
import ActionBar from "../table/ActionBar";
import Loading from "../Loading";
import { isUUID } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportFilters,
  getSavedReportsState,
} from "../../../redux/savedReports";
import { getPopups } from "../../../selectors";
import createPopup from "../../../popup-factory";

import { getReportTypeTitle } from "./helpers";
import { REPORT_TYPES } from "./report_types";
import HomePage from "../home/HomePage";
import {
  createExportedReportPopup,
  createLoadReportPopup,
} from "../../../actions/popup";
import { useHasCapabilities, useHasUserFlag } from "../../../hooks";
import styled from "styled-components";
import ScheduleReportPopup from "../schedule/ScheduleReportPopup";

const reportContainerStyle: CSSProperties = {
  width: "100%",
  minHeight: "100vh",
  height: "100%",
  background: "#E6EFF2",
  marginTop: 0,
  paddingTop: 24,
  paddingRight: 24,
};

const ReportTableContainer = styled.div`
  position: relative;
`;

const ReportContainer = () => {
  const popups = useSelector(getPopups);
  const params = useParams();
  const { param } = params;
  const report_id = isUUID(param) ? param : undefined;
  const { current_report } = useSelector(getSavedReportsState);
  const windowHeight = useWindowSize()[1];

  let report_type: string;
  if (!report_id) {
    report_type = param.toUpperCase();
  } else {
    report_type = current_report.type;
  }

  const defaultFilters = useSelector(getReportFilters(report_id));
  const reportConfig: ReportConfig = useMemo(
    () => getReportConfig(report_type),
    [report_type]
  );

  const capabilities = REPORT_TYPES?.[reportConfig.type]?.capabilities ?? [];
  const hasAccess = useHasCapabilities(capabilities);

  if (!reportConfig || !hasAccess) {
    return null;
  }

  return (
    <ReportProvider
      reportConfig={reportConfig}
      defaultFilters={defaultFilters}
      containerHeight={windowHeight}
    >
      <Csku as={"div"} className="main-content csku-styles">
        <Row>
          <Col
            xs
            sm={10}
            style={{ display: "flex", alignItems: "center", paddingLeft: 8 }}
          >
            <Link to={"/report"}>
              <ArrowIcon direction="left" size="huge" mr={10} />
            </Link>
            <DashboardPageTitle
              title={`${getReportTypeTitle(report_type)} Report`}
            />
          </Col>
          <Col xs sm={2} padded end={1}>
            <ActionMenu />
          </Col>
        </Row>

        <ReportTableContainer>
          <ReportFiltersContainer />
          <ReportSummaryContainer />
          <ReportTable />
          <Pagination />
          <Loading />
        </ReportTableContainer>

        <ActionBar />
        {popups ? popups.map((p, idx) => createPopup(p, idx)) : null}
      </Csku>
    </ReportProvider>
  );
};

const ReportsHomeContainer = () => {
  const popups = useSelector(getPopups);
  const dispatch = useDispatch();
  const hasSaveLoadReport = useHasCapabilities(["HAS-SAVE-LOAD-REPORTS"]);
  const params = useParams();
  const { param } = params;
  const report_id = isUUID(param) ? param : undefined;
  const isDownloadPage = !!useMatch("/report/download/:param");

  useEffect(() => {
    if (isDownloadPage && report_id) {
      dispatch(createExportedReportPopup(report_id));
    }
  }, []);
  const hasScheduleReport =
    useHasUserFlag(["SCHEDULED-REPORTS"]) && hasSaveLoadReport;
  const [showScheduleReport, setShowScheduleReport] = useState(false);
  const [reportId, setReportId] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scheduledReportId = urlParams.get("editSchedule");
    if (isUUID(scheduledReportId)) {
      setReportId(scheduledReportId);
      setShowScheduleReport(true);
      window.history.replaceState(null, "", "/report");
    }
  }, []);

  const handleCloseScheduleReport = () => {
    setShowScheduleReport(false);
    setReportId("");
  };

  return (
    <Csku
      as={"div"}
      className="main-content csku-styles"
      style={reportContainerStyle}
    >
      <Row>
        <Col xs={8} style={{ paddingLeft: 8 }}>
          <DashboardPageTitle title="Reports" />
        </Col>
        <Col xs={4} padded end={1}>
          {hasScheduleReport && (
            <Button mr={16} onClick={() => setShowScheduleReport(true)}>
              Schedule Report
            </Button>
          )}
          {hasSaveLoadReport && (
            <Button
              onClick={() => {
                dispatch(createLoadReportPopup());
              }}
            >
              Load Report
            </Button>
          )}
        </Col>
      </Row>
      <HomePage />
      {showScheduleReport && (
        <ScheduleReportPopup
          report_id={reportId}
          onClose={handleCloseScheduleReport}
        />
      )}
      {popups ? popups.map((p, idx) => createPopup(p, idx)) : null}
    </Csku>
  );
};

const ReportPageRouter = ({ location }) => {
  return (
    <BrowserRouter basename={location}>
      <Routes>
        <Route
          path="/report"
          element={<ReportsHomeContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/report/:param"
          element={<ReportContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/report/download/:param"
          element={<ReportsHomeContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<NoMatch location={location} />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default ReportPageRouter;
