import { Theme, themeOptions } from "@commonsku/styles";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "./Header";
import MainSection from "./MainSection";
import Overlay from "../components/Overlay";
import React from "react";
import { useSelector } from "react-redux";
import { getPopups } from "../selectors";
import '../../scss/css/react-toastify.css';

import { GlobalStyle } from "./DashboardApp";
import ReportPageRouter from "../components/report/routes/ReportPageRouter";
import withReducers from "../store/withReducers";
import SavedReportsReducer from "../redux/savedReports";
import ExportedReportsReducer from "../redux/exportedReports";
import NotesReducer from "../redux/notes";

const ReportApp = () => {
  const popups = useSelector(getPopups);

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <Header />
        <MainSection>
          <ReportPageRouter />
          <ToastContainer autoClose={3000} hideProgressBar={true} />
        </MainSection>
        <Overlay popups={popups} />
      </ErrorBoundary>
    </Theme>
  );
};

export default withReducers(ReportApp, {
  savedReports: SavedReportsReducer,
  exportedReports: ExportedReportsReducer,
  notes: NotesReducer,
});
